<template>
  <el-dialog title="图片信息" :visible.sync="showInfo" width="50%" @close="handleCancel">
    <!-- Path      string `json:"path"`
	Name      string `json:"name"`
	Kind      string `json:"kind"`
	Size      int    `json:"size"`
	UsedCount int    `json:"usedCount"` -->
    <el-descriptions class="info-wrapper" :column="3" border>
      <el-descriptions-item :span="2">
        <template slot="label">
          <!-- <i class="el-icon-user"></i> -->
          图片
        </template>
        <el-image :src="mediaIp + image.path" class="info-image" :preview-src-list="[mediaIp + image.path]"></el-image>
      </el-descriptions-item>
      <el-descriptions-item :span="2">
        <template slot="label">id</template>
        {{ image.id }}
      </el-descriptions-item>
      <el-descriptions-item :span="2">
        <template slot="label">图片名称</template>
        {{ image.name }}
      </el-descriptions-item>

      <el-descriptions-item :span="1">
        <template slot="label">图片种类</template>
        {{ image.kind }}
      </el-descriptions-item>
      <el-descriptions-item :span="2">
        <template slot="label">更新时间</template>
        {{ formatDate(image.updatedAt) }}
      </el-descriptions-item>

      <el-descriptions-item :span="1">
        <template slot="label">图片大小</template>
        {{ formatSize(image.size) }}
      </el-descriptions-item>

      <el-descriptions-item :span="2">
        <template slot="label">创建时间</template>
        {{ formatDate(image.createdAt) }}
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">被引用次数</template>
        <el-tag type="danger" v-if="image.usedCount === 0">未被引用</el-tag>
        <el-tag type="success" v-else>{{ image.usedCount }} 次</el-tag>
      </el-descriptions-item>
    </el-descriptions>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleCancel">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mediaIp } from '@/global/global'
import formatSize from '@/utils/formatSize'
import formatDate from '@/utils/formatDate'

export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    visible(n, o) {
      this.showInfo = n
    }
  },
  data() {
    return {
      showInfo: false,
      mediaIp: mediaIp,
      formatSize: formatSize,
      formatDate: formatDate
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    }
  },
  mounted() {
    console.log(formatDate)
  }
}
</script>

<style lang="scss">
.info-wrapper {
  width: 100%;

  .info-image {
    height: 200px;
  }
}
</style>
