<template>
  <el-dialog title="评论信息" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="头像" :span="2">
        <el-image :src="comment.headUrl" class="headImg" :preview-src-list="[comment.headUrl]"></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="创建时间" :span="2">{{ formatDate(comment.createdAt) }}</el-descriptions-item>
      <el-descriptions-item label="昵称" :span="2">{{ comment.nickname }}</el-descriptions-item>
      <el-descriptions-item label="网站" :span="2">{{ comment.website }}</el-descriptions-item>

      <el-descriptions-item label="用户IP" :span="2">{{ comment.ip }}</el-descriptions-item>
      <el-descriptions-item label="用户设备" :span="2">{{ comment.info }}</el-descriptions-item>
      <el-descriptions-item label="评论内容">{{ comment.content }}</el-descriptions-item>
    </el-descriptions>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import formatDate from '@/utils/formatDate'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    comment: {
      type: Object,
      default: false
    }
  },

  watch: {
    visible(n) {
      this.dialogVisible = n
    }
  },
  data() {
    return {
      dialogVisible: false,
      formatDate: formatDate
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.headImg {
  width: 80px;
  height: 80px;
}
</style>
