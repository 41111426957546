import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import axios from 'axios';
import {api} from'@/global/global'

// 图片裁剪器
import VueCropper from 'vue-cropper'

// markdown
import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
// import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

// codemirror 编辑器的相关资源
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
// edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';

import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';

// 数学符号组件
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';

// 流程图
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';

// 任务列表
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';

// 引入html展示组件
import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import '@kangc/v-md-editor/lib/style/preview-html.css';

// 内容位置
import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';

import 'echarts'  //全量引入echarts
import vecharts from 'vue-echarts'; //引入vue-echarts
Vue.component('v-chart',vecharts); //全局声明 v-chart 组件



VMdEditor.Codemirror = Codemirror;
VMdEditor.use(createAlignPlugin())
VMdEditor.use(vuepressTheme, {
  Prism,
  Hljs: hljs,

  extend(md) {
    // md为 markdown-it 实例，可以在此处进行修改配置,并使用 plugin 进行语法扩展
    // md.set(option).use(plugin);
  },
  
});
VMdEditor.use(createEmojiPlugin())
VMdEditor.use(createKatexPlugin())
VMdEditor.use(createMermaidPlugin())
VMdEditor.use(createTodoListPlugin())

Vue.use(VMdEditor);

// html展示组件
Vue.use(VMdPreviewHtml)

// 图片裁剪
Vue.use(VueCropper)


// axios 配置生产环境
const instance = axios.create({
  // 基础URL，在非生产环境中会被拦截器修改
  baseURL: api,
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(config => {
  // 在非生产环境中修改请求的URL
  if (process.env.NODE_ENV !== 'production') {
    config.url = api + config.url;
  }

  const token =  window.sessionStorage.getItem("token")
  if(token){
    config.headers.Authorization =  token
  }

  return config;
});

// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    
    // console.log(response)
    if(response.data.status===504||response.data.status===503){
      window.sessionStorage.removeItem("token")
      ElementUI.Message.error("token已过期")
    }

    return response;
  },
);

Vue.prototype.$http = instance
Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
