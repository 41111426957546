<template>
  <div>
    <el-row>
      <el-col :span="12">
        <v-chart class="chart item" :option="articleOptions"></v-chart>
      </el-col>
      <el-col :span="12">
        <v-chart class="chart item" :option="customerOptions"></v-chart>

        <span class="tips">
          每1分钟更新一次数据
          <i class="el-icon-refresh-left"></i>
        </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24"><v-chart class="chart item" :option="tagOptions"></v-chart></el-col>
    </el-row>
    <el-row>
      <!-- 资源总大小 -->
      <!-- 文章总数量 -->
      <!-- 评论总数 -->
      <!-- 小功能总数 -->

      <el-col :span="6">
        <div class="func item" style="color: #ee6666">
          <div class="top">
            <i class="el-icon-upload func-icon"></i>
            <div class="title">资源总大小</div>
          </div>
          <div class="num">{{ formatSize(imageInfo.size) }}/{{ imageInfo.count }}张</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="func item" style="color: #73c0de">
          <div class="top">
            <i class="el-icon-s-management func-icon"></i>
            <div class="title">文章总数量</div>
          </div>
          <div class="num">{{ articleCount }}篇</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="func item" style="color: #91cc75">
          <div class="top">
            <i class="el-icon-s-comment func-icon"></i>
            <div class="title">评论总数</div>
          </div>
          <div class="num">{{ commentCount }}条</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="func item" style="color: #fac858">
          <div class="top">
            <i class="el-icon-s-opportunity func-icon"></i>
            <div class="title">小功能总数</div>
          </div>
          <div class="num">100个</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { api } from '@/global/global'
import formatSize from '@/utils/formatSize'
export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin',
      articleCount: 0,
      commentCount: 0,
      imageInfo: {
        size: 0,
        count: 0
      },
      formatSize: formatSize,
      articleOptions: {
        title: {
          text: '每日文章量',
          top: '2%',
          left: '1%'
        },
        tooltip: {},
        xAxis: {
          data: ['08-17', '08-18', '08-19', '08-20', '08-21', '08-22']
        },
        yAxis: {},
        series: [
          {
            name: '文章数量',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      },
      customerOptions: {
        title: {
          text: '每日访客量',
          top: '2%',
          left: '1%'
        },
        xAxis: {
          type: 'category',
          data: ['08-17', '08-18', '08-19', '08-20', '08-21', '08-22']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '访客量',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            itemStyle: { normal: { label: { show: true } } }
          }
        ]
      },
      tagOptions: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: '1%',
          top: '3%',
          bottom: '3%'
        },
        series: [
          {
            name: '标签',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {
    async getAllArticleCount() {
      const { data: res } = await this.$http.get(this.api + '/getAllArticleCount')
      if (res.status != 200) {
        this.$message.error('获取文章数量失败')
        return
      }

      this.articleCount = res.data
    },
    async getAllCommentCount() {
      const { data: res } = await this.$http.get(this.api + '/getAllCommentCount')
      if (res.status != 200) {
        this.$message.error('获取评论数量失败')
        return
      }

      this.commentCount = res.data
    },
    async getAllImageInfo() {
      const { data: res } = await this.$http.get('/admin/getAllImageSizeAndCount')
      if (res.status != 200) {
        this.$message.error('获取所有图片大小和数量失败')
        return
      }
      // console.log(res)

      this.imageInfo = res.data
    },
    async getAllTagCount() {
      const { data: res } = await this.$http.get(this.api + '/getAllTagCount')
      if (res.status != 200) {
        this.$message.error('获取所有tag数量失败')
        return
      }

      this.tagOptions.series[0].data = res.data
    },
    async get7DaysArticleCount() {
      const { data: res } = await this.$http.get(this.api + '/get7DaysArticleCountByCreateTime')
      if (res.status != 200) {
        this.$message.error('按时间获取文章数量失败')
        return
      }

      this.articleOptions.xAxis.data = res.data.dateList
      this.articleOptions.series[0].data = res.data.countList
    },
    async get7DaysUserCount() {
      const { data: res } = await this.$http.get(this.api + '/get7DaysUserCountByCreateTime')
      if (res.status != 200) {
        this.$message.error('按时间获取访客数量失败')
        return
      }

      this.customerOptions.xAxis.data = res.data.dateList
      this.customerOptions.series[0].data = res.data.countList
    }
  },
  mounted() {
    // 绘制图表,加载数据
    this.getAllTagCount()
    this.get7DaysArticleCount()
    this.getAllImageInfo()
    this.getAllArticleCount()
    this.getAllCommentCount()
    this.get7DaysUserCount()

    setInterval(() => {
      this.get7DaysUserCount()
    }, 1000 * 60)
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 300px;
}

.item {
  background-color: #fff;
  // box-shadow: rgba(0, 0, 0, 0.3) 0px 0.1px 18px;
  width: 100%;
}

.el-col {
  padding: 10px;
  padding-top: 0;
  box-sizing: border-box;
  color: #818286;
  letter-spacing: 1px;
  position: relative;

  .tips {
    width: 200px;

    position: absolute;
    bottom: 20px;
    font-size: 10px;
    text-align: center;
    left: calc(50% - 100px);
  }
}

.el-row {
  margin-bottom: 10px;
}

.func {
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  .top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .func-icon {
      // width: 40px;
      // height: 40px;
      margin-right: 20px;
      font-size: 50px;
    }

    .title {
      // font-size: 14px;
    }
  }

  .num {
    margin-top: 20px;
    font-size: 25px;
  }
}
</style>
