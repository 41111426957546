<template>
  <div>
    <div class="wrapper">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文章管理</el-breadcrumb-item>
        <el-breadcrumb-item>添加 · 编辑</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- markdown编辑器 -->
      <el-row class="article-info">
        <el-col :span="6" :offset="7">
          <el-select
            style="width: 100%"
            v-model="title"
            filterable
            allow-create
            default-first-option
            placeholder="请输入文章题目进行添加/搜索"
            remote
            :remote-method="remoteMethod"
            @change="handleChange"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.title"
              :value="'id:' + item.id"
              :value-key="item.id"
            >
              <i class="el-icon-success" style="color: #67c23a"></i>
              {{ item.title }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-button type="primary" @click="showInfo = true">编辑文章其他信息</el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showImageManager = !showImageManager">文章图片管理</el-button>
        </el-col>
      </el-row>

      <v-md-editor
        v-model="text"
        class="markdown-wrapper"
        default-show-toc
        :include-level="[1, 2, 3]"
        @save="handleSave"
        @change="handleChangeTxt"
      ></v-md-editor>

      <!-- 文章其他信息 -->
      <ArticleInfoCompontent
        :visible="showInfo"
        @cancel="showInfo = false"
        @confirm="handleConfirmInfo"
        :info="info"
      ></ArticleInfoCompontent>
      <!-- 文章图片管理 -->
      <ArticleImageComponent
        :visible="showImageManager"
        @close="showImageManager = false"
        :articleImageList="articleImagePaths"
        @submitCover="handleSubmitCover"
        :coverUrl="coverUrl"
      ></ArticleImageComponent>
    </div>
  </div>
</template>

<script>
import ArticleInfoCompontent from '@/components/ArticleInfoCompontent.vue'
import ArticleImageComponent from '@/components/ArticleImageComponent.vue'
import MySelectComponent from '@/components/MySelectComponent.vue'
import { mediaIp, jsonDataConfig, formDataConfig, api } from '@/global/global'

export default {
  components: {
    ArticleInfoCompontent,
    ArticleImageComponent,
    MySelectComponent
  },
  data() {
    return {
      showInfo: false,
      showImageManager: false,
      text: '',
      title: '',
      articleImagePaths: [],
      info: {
        typeName: '',
        desc: '',
        tagName: '',
        title: ''
      },
      options: [],
      id: '',
      article: null,
      coverUrl: '',
      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin'
    }
  },
  methods: {
    handleConfirmInfo(e) {
      this.info = e
      this.showInfo = false
    },

    handleChangeTxt(text, html) {
      // console.log(text)
      const pattern = /!\[(.*?)\]\((.*?)\)/g
      let match
      while ((match = pattern.exec(text)) !== null) {
        let link = match[2]
        link = link.split(mediaIp)[1]
        if (link) {
          let exsit = false
          this.articleImagePaths.forEach((item) => {
            if (item.path === link) {
              exsit = true
            }
          })

          if (!exsit) {
            this.articleImagePaths.push({
              id: '',
              path: link
            })
          }
        }

        // console.log(link)
      }
    },
    async handleSave(text, html) {
      // console.log(text, html)

      if (
        this.info.desc.length === 0 ||
        this.info.desc === '' ||
        this.info.typeName.length === 0 ||
        this.info.typeName === '' ||
        this.info.tagName.length === 0 ||
        this.info.tagName === '' ||
        this.info.title.length === 0 ||
        this.info.title === ''
      ) {
        this.showConfirm('文章其他信息未完善')
        return
      }
      if (!this.text) {
        this.showConfirm('请输入文章内容')
        return
      }

      if (!this.coverUrl) {
        this.showConfirm('请打开图片管理上传文章封面图片')
        return
      }

      if (this.id && this.article != null) {
        this.updateArticle(text, html)
        return
      }

      // 保存上传内容
      const { data: res } = await this.$http.post(
        '/admin/saveArticle',
        {
          imageList: this.articleImagePaths,
          content: html,
          markdownText: text,
          title: this.title,
          coverUrl: this.coverUrl,
          ...this.info
        },
        jsonDataConfig
      )

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }
      this.clearForm()
      // 提示成功
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    },
    clearForm() {
      this.text = ''
      this.title = ''
      this.articleImagePaths = []
      this.options = []
      this.info = {
        typeName: '',
        desc: '',
        tagName: '',
        title: ''
      }
    },
    async updateArticle(text, html) {
      let newArticle = this.article

      newArticle.imageList = this.articleImagePaths
      newArticle.content = html
      newArticle.markdownText = text
      newArticle.title = this.info.title
      newArticle.typeName = this.info.typeName
      newArticle.desc = this.info.desc
      newArticle.tagName = this.info.tagName
      newArticle.coverUrl = this.coverUrl

      console.log(newArticle)

      const { data: res } = await this.$http.put('/admin/updateArticle', newArticle, jsonDataConfig)

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }
      this.clearForm()

      // 提示成功
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    },
    showConfirm(content) {
      this.$confirm(content, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      })
    },
    async remoteMethod(e) {
      const { data: res } = await this.$http.get(this.api + '/getArticleByTitleBlur', {
        params: { title: e, pageNum: 1, size: 100, isPager: false }
      })
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
      }
      // console.log(res)
      this.options = res.data.list
    },
    async handleChange(e) {
      if (e.indexOf('id:') === -1) {
        console.log('加入了')
        this.title = e
        this.info.title = e
        console.log(e)
        return
      }

      const id = e.split('id:')[1]
      // 搜索文章内容

      const { data: res } = await this.$http.get(this.api + '/getArticleById', { params: { id: id } })

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      // 传递数据
      const article = res.data
      this.article = article
      this.title = article.title
      this.id = article.id
      this.coverUrl = article.coverUrl
      this.text = article.markdownText
      this.articleImagePaths = article.imageList
      this.info = {
        typeName: article.typeName,
        desc: article.desc,
        tagName: article.tagName,
        title: article.title
      }
    },
    async handleSubmitCover(e) {
      const { data: res } = await this.$http.post(
        '/admin/uploadArticleCover',
        {
          base64: e.base64,
          adminName: 'superlwb'
        },
        formDataConfig
      )
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify({
        title: '成功',
        message: '上传文章封面成功，请保存文章信息',
        type: 'success'
      })

      this.coverUrl = res.data.path
    }
  }
}
</script>

<style lang="scss" scoped>
.markdown-wrapper {
  height: 90%;
  margin-top: 10px;
}

.article-info {
  margin-top: 10px;
}
</style>
