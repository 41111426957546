<template>
  <el-menu
    :default-active="defaultActive"
    class="app-el-menu"
    :collapse="show"
    background-color="#304156"
    text-color="#bfcbd9"
    :router="true"
  >
    <el-menu-item>
      <div v-if="iscollapse">欢迎</div>
      <span slot="title"><img src="../assets/logo.png" alt="" style="width: 80px; height: 40px" /></span>
    </el-menu-item>

    <el-menu-item index="/welcome">
      <i class="el-icon-s-home"></i>
      <span slot="title">首页</span>
    </el-menu-item>

    <el-submenu index="2">
      <template slot="title">
        <i class="el-icon-s-management"></i>
        <span slot="title">文章管理</span>
      </template>

      <el-menu-item index="/addOrEidtArticle">添加 / 编辑文章</el-menu-item>
      <el-menu-item index="/articleOrCommnet">查看文章 / 评论管理</el-menu-item>
    </el-submenu>

    <el-submenu index="3">
      <template slot="title">
        <i class="el-icon-user-solid"></i>
        <span slot="title">博客管理</span>
      </template>

      <el-menu-item index="/adminInfo">
        <span slot="title">管理员信息</span>
      </el-menu-item>
      <el-menu-item index="/blogComment">评论管理</el-menu-item>
      <el-menu-item index="/blogConfig">博客设置</el-menu-item>
    </el-submenu>

    <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-picture"></i>
        <span slot="title">图管理</span>
      </template>

      <el-menu-item index="/swiper">轮播图</el-menu-item>
      <el-menu-item index="/image">图片</el-menu-item>
    </el-submenu>

    <el-menu-item index="/function">
      <i class="el-icon-message-solid"></i>
      <span slot="title">小功能管理</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'MenuComponent',
  props: {
    iscollapse: {
      type: Boolean,
      default: true,
      required: true
    }
  },

  watch: {
    iscollapse(n) {
      this.show = n
    }
  },

  data() {
    return {
      defaultActive: '/',
      show: true
    }
  },
  methods: {},
  mounted() {
    // 设置初始化菜单选项
    this.defaultActive = this.$route.path
    this.$router.afterEach((to, from) => {
      this.defaultActive = to.path
    })
  }
}
</script>

<style lang="scss" scoped>
.el-menu {
  border: none;
}

.el-menu--collapse {
  /* 缩小后的样式 */
  width: 80px; /* 设置宽度 */
}

.app-el-menu:not(.el-menu--collapse) {
  width: 250px;
  height: 100%;
}
</style>
