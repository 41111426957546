<template>
  <div class="login-wrapper">
    <img src="../assets/logo.png" alt="" class="logo" />
    <background></background>
    <div class="login-info">
      <div class="title item">Login</div>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm" label-position="top">
        <el-form-item label="username" prop="username" class="item">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="password" prop="password" class="item">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>

        <el-button type="primary" @click="handleLogin">登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { formDataConfig } from '@/global/global'
import Background from '@/components/BackgroundComponent.vue'

export default {
  name: 'LoginView',
  components: {
    Background
  },

  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '管理员账号', trigger: 'blur' }],
        password: [{ required: true, message: '管理员密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleLogin() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        //登录
        const { data: res } = await this.$http.post(
          '/login/',
          {
            username: this.form.username,
            password: this.form.password
          },
          formDataConfig
        )
        if (res.status != 200) {
          this.$message.error(res.message)
          return
        }

        this.$message.success('尊贵的管理员，欢迎回家(❁´◡`❁)！😊')

        window.sessionStorage.setItem('token', res.data.token)
        this.$http.defaults.headers.common['Authorization'] = `${res.data.token}`
        this.$router.push('/home')

        window.sessionStorage.setItem('info', JSON.stringify(res.data.info))
      })
    },

    keyDownFunc(e) {
      if (e.code === 'Enter') {
        this.handleLogin()
      }
    }
  },
  beforeDestroy() {
    window.document.removeEventListener('keydown', this.keyDownFunc)
  },
  mounted() {
    window.document.addEventListener('keydown', this.keyDownFunc)
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  .item {
    margin-top: 20px;
  }

  .title {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 900;
  }

  .login-info {
    padding: 20px;
    width: 600px;
    height: 400px;
    background-color: rgba($color: #fff, $alpha: 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    // position: relative;
  }

  .demo-ruleForm {
    .el-button {
      display: block;
      margin: 0 auto;
    }
  }
}

.logo {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 10px;
  left: 20px;
  display: none;
}
</style>
