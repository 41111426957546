<template>
  <el-dialog title="文章其他信息" :visible.sync="visible" @close="handleCancel">
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="文章类型" :label-width="formLabelWidth" prop="typeName">
        <el-col :span="8">
          <el-select v-model="form.typeName" filterable allow-create default-first-option placeholder="请选择文章标签">
            <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="文章描述" :label-width="formLabelWidth" prop="desc">
        <el-col :span="20"><el-input v-model="form.desc" placeholder="请输入文章描述"></el-input></el-col>
      </el-form-item>
      <el-form-item label="文章标签" :label-width="formLabelWidth" prop="tagName">
        <el-col :span="8">
          <el-select v-model="form.tagName" filterable allow-create default-first-option placeholder="请选择文章标签">
            <el-option v-for="item in tagList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="文章题目" :label-width="formLabelWidth" prop="title">
        <el-col :span="20"><el-input v-model="form.title" placeholder="请输入文章题目"></el-input></el-col>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { api } from '@/global/global'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    info: Object
  },

  watch: {
    visible(n, o) {
      this.dialogFormVisible = n
      if (n) {
        this.getType()
        this.getTag()
      }
    },
    info: {
      handler(newVal, oldVal) {
        if (newVal === null) {
          this.$refs.form.resetFields()
          return
        }
        this.form = newVal
      },
      deep: true
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: 100,
      rules: {
        typeName: [{ required: true, message: '请选择文章类型', trigger: 'blur' }],
        tagName: [{ required: true, message: '请选择文章标签', trigger: 'blur' }],
        desc: [
          { required: true, message: '请输入文章描述', trigger: 'blur' },
          { max: 80, message: '最多80个字', trigger: 'blur' }
        ],
        title: [{ required: true, message: '请输入文章题目', trigger: 'blur' }]
      },
      form: {
        typeName: '',
        desc: '',
        tagName: '',
        title: ''
      },
      tagList: [],
      typeList: [],

      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin'
    }
  },

  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      var flag = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          flag = false
          return
        }
      })
      if (!flag) return
      this.$emit('confirm', this.form)
    },
    async getType() {
      const { data: res } = await this.$http.get(this.api + '/getAllType')
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }
      this.typeList = res.data
    },

    async getTag() {
      const { data: res } = await this.$http.get(this.api + '/getAllTag')
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }
      this.tagList = res.data
    }
  },
  mounted() {
    this.getType()
    this.getTag()
  }
}
</script>

<style lang="scss" scoped></style>
