<template>
  <div>
    <div class="wrapper">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>管理员信息</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- form表单 -->
      <!-- 图片 -->
      <!-- 信息 -->

      <el-form label-width="80px" :model="form" class="form" ref="formRef">
        <el-form-item label="头像">
          <el-avatar :size="60">
            <template slot="default">
              <img :src="mediaIp + form.headPath" alt="" @click="chooseVisible = true" />
            </template>
          </el-avatar>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="form.sex" label="男">男</el-radio>
          <el-radio v-model="form.sex" label="女">女</el-radio>
        </el-form-item>
        <el-form-item label="年龄" prop="age"><el-input type="number" v-model="form.age"></el-input></el-form-item>
        <el-form-item label="座右铭" prop="moto"><el-input v-model="form.moto"></el-input></el-form-item>
        <el-form-item label="工作" prop="job"><el-input v-model="form.job"></el-input></el-form-item>
        <el-form-item label="爱好" prop="hobby"><el-input v-model="form.hobby"></el-input></el-form-item>
        <el-form-item label="性格" prop="character"><el-input v-model="form.character"></el-input></el-form-item>
        <el-form-item label="QQ" prop="QQ"><el-input v-model="form.QQ"></el-input></el-form-item>
        <el-form-item label="邮箱" prop="email"><el-input v-model="form.email"></el-input></el-form-item>
        <el-form-item label="Gitee" prop="gitee"><el-input v-model="form.gitee"></el-input></el-form-item>
        <el-form-item label="Github" prop="github"><el-input v-model="form.github"></el-input></el-form-item>
        <div class="form-btn">
          <el-button type="primary" @click="handleSubmitForm">保存</el-button>
          <el-button type="danger" @click="handleReset">重置</el-button>
        </div>
      </el-form>
    </div>

    <cut-image
      :visible.sync="chooseVisible"
      @close="chooseVisible = false"
      @submit="handleSubmitHeadImg"
      :option="option"
    ></cut-image>
  </div>
</template>

<script>
import { cuterDefualtOptions, formDataConfig, jsonDataConfig, mediaIp, api } from '@/global/global'
import CutImage from '@/components/CutImageComponent.vue'

export default {
  components: {
    CutImage
  },
  data() {
    return {
      form: {
        headPath: '',
        age: '',
        sex: '',
        nickname: '',
        job: '',
        hobby: '',
        character: '',
        QQ: '',
        email: '',
        gitee: '',
        github: '',
        username: '',
        moto: ''
      },
      chooseVisible: false,
      option: cuterDefualtOptions,
      mediaIp: mediaIp,
      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin'
    }
  },
  methods: {
    async handleSubmitHeadImg(e) {
      const { data: res } = await this.$http.post(
        '/admin/uploadHeadImg',
        {
          base64: e.base64,
          adminName: JSON.parse(window.sessionStorage.getItem('info')).username
        },
        formDataConfig
      )
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify({
        title: '成功',
        message: '上传管理员头像成功，请保存信息',
        type: 'success'
      })

      this.form.headPath = res.data.path

      this.chooseVisible = false
    },
    async getAdminInfo() {
      // 管理员账号
      const adminName = JSON.parse(window.sessionStorage.getItem('info')).username

      const { data: res } = await this.$http.get(this.api + '/info', { params: { username: adminName } })

      console.log(res)
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.form = res.data
    },
    async handleSubmitForm() {
      this.form.username = JSON.parse(window.sessionStorage.getItem('info')).username
      this.form.age = parseInt(this.form.age)
      const { data: res } = await this.$http.put('/admin/update', this.form, jsonDataConfig)

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.getAdminInfo()
      this.$notify.success({
        title: '成功',
        message: '保存管理员信息成功'
      })
    },

    // 这里重置失败了操
    handleReset() {
      this.form.headPath = ''
      this.$refs.formRef.resetFields()
    }
  },
  mounted() {
    // 获取管理员信息
    this.getAdminInfo()
  }
}
</script>

<style lang="scss">
.form {
  width: 600px;
  margin: 0 auto;
  position: relative;
  // top: 20px;
  // padding: 10px;
  // box-sizing: border-box;

  .inline-block {
    vertical-align: top;
    display: inline-block;
  }

  .form-btn {
    text-align: center;
    position: absolute;
    bottom: 0%;
    right: calc(50% - 500px);
  }
  .el-avatar {
    position: relative;
    cursor: pointer;
  }

  .el-avatar:hover::after {
    content: '+';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 9;
    color: #fff;
    font-size: 30px;
    // 事件穿透
    pointer-events: none;
  }

  .el-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // background-color: #000000;

    .el-input {
      width: 300px;
    }
  }
}
</style>
