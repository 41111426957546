<template>
  <div>
    <div class="wrapper">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>图管理</el-breadcrumb-item>
        <el-breadcrumb-item>
          轮播图管理
          <span class="wranning-span">
            <i class="el-icon-warning"></i>
            服务器重启会
            <span class="important">清空轮播图列表</span>
            ，需要重新设置噢！
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <div class="swiper-wrapper">
        <el-carousel trigger="click" height="700px">
          <el-carousel-item v-for="item in swiperList" :key="item.id" class="swiper-item">
            <div class="article-cover">
              <el-image class="swiper-image" :src="mediaIp + item.coverUrl"></el-image>
              <div class="article-info">
                <div class="title">{{ item.title }}</div>
                <div class="date">{{ formatDate(item.date) }}</div>
                <div class="delete" @click="handleDelete(item.id)"><i class="el-icon-error"></i></div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item class="swiper-item">
            <div class="info-wrapper" @click="showAddSwiper = true"><div class="info">添加轮播图</div></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <AddSwiperComponent
      :visible="showAddSwiper"
      @submit="handleSubmit"
      @close="showAddSwiper = false"
    ></AddSwiperComponent>
  </div>
</template>

<script>
import AddSwiperComponent from '@/components/AddSwiperComponent.vue'
import { formDataConfig, api, mediaIp } from '@/global/global'
import formatDate from '@/utils/formatDate'
export default {
  components: {
    AddSwiperComponent
  },
  data() {
    return {
      showAddSwiper: false,
      swiperList: [],
      mediaIp: mediaIp,
      formatDate: formatDate,
      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin'
    }
  },
  methods: {
    async handleSubmit(e) {
      this.showAddSwiper = false
      const { data: res } = await this.$http.post(
        '/admin/addSwiperArticle',
        {
          id: e
        },
        formDataConfig
      )

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify({
        title: '成功',
        message: res.message,
        type: 'success'
      })

      this.getSwiperList()
    },
    async getSwiperList() {
      const { data: res } = await this.$http.get(this.api + '/getSwiperArticleList')

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      // console.log(res)

      this.swiperList = res.data
    },
    async handleDelete(id) {
      const resCom = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (resCom === undefined) {
        return
      }

      // 删除
      const { data: res } = await this.$http.delete('/admin/deleteSwiperArticle', { params: { id: id } })

      console.log(res)
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify({
        title: '成功',
        type: 'success',
        message: res.message
      })
      this.getSwiperList()
    }
  },
  mounted() {
    this.getSwiperList()
  }
}
</script>

<style lang="scss" scoped>
$swiperHeight: 700px;

.swiper-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.el-carousel {
  width: 1000px;
  height: $swiperHeight;

  .article-cover {
    width: 100%;
    height: 100%;
    position: relative;

    .article-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;

      .title {
        font-size: 30px;
        margin: 10px;
      }

      .delete {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 40px;
        cursor: pointer;
      }

      .delete:hover {
        color: #f56c6c;
      }
    }
  }

  .swiper-item {
    width: 100%;
    height: $swiperHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: relative;
    .swiper-image {
      width: 100%;
      height: 100%;
    }

    .info-wrapper {
      width: 100%;
      height: 100%;
      background-color: #a9a9b3;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .info {
        margin-top: 80px;
      }
    }

    .info-wrapper::after {
      content: '+';
      position: absolute;
      top: calc(50% - 250px);
      left: calc(50% - 250px);
      width: 500px;
      height: 500px;
      border-radius: 5px;
      border: #d3dce6 5px dashed;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 900;
      color: #d3dce6;
    }
  }
}
</style>
