<template>
  <el-dialog
    title="裁剪图片"
    width="60%"
    :visible.sync="localVisible"
    @close="handleClose"
    :modal="modal"
    :close-on-click-modal="modal"
    :fullscreen="!modal"
  >
    <div class="cup-wrapper" :style="'width:' + cutwidth + 'px;' + 'height:' + cutwidth + 'px;'">
      <vueCropper
        ref="cropper"
        :img="option.img"
        :outputSize="option.outputSize"
        :outputType="option.outputType"
        :info="option.info"
        :canScale="option.canScale"
        :autoCrop="option.autoCrop"
        :autoCropWidth="option.autoCropWidth"
        :autoCropHeight="option.autoCropHeight"
        :fixed="option.fixed"
        :fixedNumber="option.fixedNumber"
        :full="option.full"
        :fixedBox="option.fixedBox"
        :canMove="option.canMove"
        :canMoveBox="option.canMoveBox"
        :original="option.original"
        :centerBox="option.centerBox"
        :height="option.height"
        :infoTrue="option.infoTrue"
        :maxImgSize="option.maxImgSize"
        :enlarge="option.enlarge"
        :mode="option.mode"
      ></vueCropper>
      <input type="file" ref="fileInput" style="display: none" @change="handleImageChange" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-col :span="16"><el-input placeholder="这里可以输入url" v-model="inputUrl" clearable></el-input></el-col>
      <el-button type="primary" @click="handleChooseImage">上传图片</el-button>
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      default: false,
      type: Boolean,
      required: true
    },
    // 裁剪器选项
    option: {
      type: Object,
      required: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    cutwidth: {
      type: Number,
      default: 300
    },
    cutheigth: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      localVisible: this.visible,
      selectedFile: null,
      inputUrl: ''
    }
  },
  watch: {
    visible() {
      this.localVisible = this.visible
      console.log(this.visible)
    },
    inputUrl() {
      this.option.img = this.inputUrl
      console.log(this.option.img)
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleSubmit() {
      this.$refs.cropper.getCropData((data) => {
        // do something
        this.$emit('submit', {
          base64: data
        })
        // console.log(data)
      })
    },
    handleChooseImage() {
      this.$refs.fileInput.click()
    },
    handleImageChange(event) {
      this.selectedFile = event.target.files[0]
      // console.log(event)

      if (this.selectedFile) {
        this.option.img = URL.createObjectURL(this.selectedFile)
      } else {
        this.option.img = null
      }

      console.log(this.option.img)
    }
  }
}
</script>

<style lang="scss" scoped>
// 提示框
.cup-wrapper {
  margin: 0 auto;
}
</style>
