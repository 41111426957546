<template>
  <div>
    <div class="wrapper">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>文章管理</el-breadcrumb-item>
        <el-breadcrumb-item>
          查看 · 评论管理
          <span class="wranning-span">
            <i class="el-icon-warning"></i>
            服务器重启会
            <span class="important">清空推荐列表</span>
            ，需要重新设置噢！
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <div class="table-wrapper">
        <el-table :data="tableData" class="table" height="720">
          <el-table-column type="expand">
            <template slot-scope="scope" v-if="scope.row.commentCount != 0">
              <div class="title">评论管理({{ scope.row.commentCount }})</div>
              <el-row
                v-for="(message, i1) in scope.row.commentList"
                :key="message.id"
                :class="[
                  i1 != scope.row.commentList.length - 1 ? 'bdbottom' : '',
                  i1 === 0 ? 'bdtop' : '',
                  'flex-end-center'
                ]"
              >
                <el-col :span="10">
                  <el-tag
                    :key="message.id"
                    closable
                    @click="handleShowCommentInfo(message)"
                    @close="handleDeleteMessage(scope.row, message)"
                  >
                    {{ message.content }}
                  </el-tag>
                </el-col>
                <el-col :span="10">
                  <el-row
                    v-for="(comment, i2) in message.commentList"
                    :key="comment.id"
                    :class="[i2 === 0 ? '' : 'bdtop', 'flex-start-center']"
                  >
                    <el-tag
                      :key="comment.id"
                      closable
                      type="success"
                      @click="handleShowCommentInfo(comment)"
                      @close="handleDeleteComment(scope.row, comment, i1)"
                    >
                      {{ comment.content }}
                    </el-tag>
                  </el-row>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="文章ID" prop="id" width="100"></el-table-column>
          <el-table-column label="文章封面" prop="coverUrl" width="200">
            <template slot-scope="scope">
              <el-image
                :src="mediaIp + scope.row.coverUrl"
                class="article-cover"
                :preview-src-list="[mediaIp + scope.row.coverUrl]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="文章题目" prop="title"></el-table-column>
          <el-table-column label="文章类型" width="100">
            <template slot-scope="scope">
              {{ scope.row.typeName }}
            </template>
          </el-table-column>
          <el-table-column label="文章种类" width="100">
            <template slot-scope="scope">
              {{ scope.row.tagName }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="100">
            <template slot-scope="scope">
              {{ formatDate(scope.row.createdAt) }}
            </template>
          </el-table-column>
          <el-table-column label="浏览量" prop="watchCount" width="70"></el-table-column>
          <el-table-column label="评论数" width="70" prop="commentCount"></el-table-column>

          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button @click="handleShowInfo(scope.row)" type="text">查看</el-button>
              <el-button
                @click="handleSetRecommend(scope.row, getRecommendArticlePos(scope.row.id) ? '-1' : '0')"
                type="text"
                :style="getRecommendArticlePos(scope.row.id) ? 'color:orange;' : 'color: #67c23a;'"
              >
                设为推荐
                <span v-if="getRecommendArticlePos(scope.row.id)">({{ getRecommendArticlePos(scope.row.id) }})</span>
              </el-button>
              <el-button type="text" style="color: #f56c6c" @click="handleDeleteById(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pageter">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="totalLength"
          :page-size="size"
          :current-page="pageNum"
          @current-change="handleCurrentPageNum"
        ></el-pagination>
      </div>
    </div>
    <CommentComponent :visible="showComment" :comment="currComment" @close="showComment = false"></CommentComponent>
    <ArticleComponent :visible="showInfo" :article="currArticle" @close="showInfo = false"></ArticleComponent>
  </div>
</template>

<script>
import { mediaIp, formDataConfig, api } from '@/global/global'
import formatDate from '@/utils/formatDate'
import ArticleComponent from '@/components/ArticleComponent.vue'
import CommentComponent from '@/components/CommentComponent.vue'
export default {
  components: {
    ArticleComponent,
    CommentComponent
  },
  data() {
    return {
      pageNum: 1,
      size: 4,
      totalLength: 0,
      tableData: [],
      mediaIp: mediaIp,
      formatDate: formatDate,
      showInfo: false,
      showComment: false,
      currArticle: {
        commentList: []
      },
      currComment: {},
      recommendList: [],
      // 需要搜索的内容
      searchInput: '',

      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin'
    }
  },
  watch: {
    pageNum(n, o) {
      this.getData()
    }
  },
  methods: {
    handleShowInfo(e) {
      this.showInfo = true
      this.currArticle = e
    },
    async getData() {
      const { data: res } = await this.$http.get(this.api + '/getAllArticle', {
        params: {
          pageNum: this.pageNum,
          size: this.size
        }
      })

      this.totalLength = res.data.total

      this.tableData = res.data.list
    },
    handleShowCommentInfo(obj) {
      this.currComment = obj
      this.showComment = true
    },
    async handleDeleteMessage(row, mes) {
      const confirmRes = await this.$confirm('此操作将该评论删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (confirmRes !== 'confirm') {
        return
      }

      const { data: res } = await this.$http.delete('/admin/deleteMessageById', {
        params: {
          id: mes.id,
          articleId: row.id
        }
      })

      if (res.status != 200) {
        this.$message.error('删除失败')
        console.log(res.message)
        return
      }

      this.$message({
        type: 'success',
        message: '删除成功!'
      })
      row.commentList = res.data
    },
    async handleDeleteComment(row, com, i1) {
      const confirmRes = await this.$confirm('此操作将该评论删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (confirmRes !== 'confirm') {
        return
      }

      const { data: res } = await this.$http.delete('/admin/deleteCommentById', {
        params: {
          msgId: com.messageId,
          id: com.id
        }
      })

      if (res.status != 200) {
        this.$message.error('删除失败')
        console.log(res.message)
        return
      }

      this.$message({
        type: 'success',
        message: '删除成功!'
      })

      row.commentList[i1].commentList = res.data.commentList
      // console.log(row)
    },
    async handleDeleteById(id) {
      const confirmRes = await this.$confirm('此操作将该文章删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (confirmRes !== 'confirm') {
        return
      }
      const { data: res } = await this.$http.delete('/admin/logicDeleteArticleById', {
        params: {
          id: id
        }
      })

      if (res.status != 200) {
        this.$message.error('删除失败')
        console.log(res.message)
        return
      }

      this.$message({
        type: 'success',
        message: '删除成功!'
      })

      this.getData()
    },
    async handleSetRecommend(article, defaultValue) {
      const id = article.id
      const resCom = await this.$prompt('请输入展示的位置，0(添加)，-1(删除)，其他数字(转换位置)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入在推荐榜的位置',
        inputValue: defaultValue,
        inputPattern: /^[+-]?[0-9]$/,
        inputErrorMessage: '请输入数字'
      }).catch(() => {})

      if (resCom === undefined) {
        return
      }

      // 删除
      if (resCom.value == -1) {
        this.deleteRecommendArticle(id)
        return
      }

      // 添加
      this.addRecommendArticle(resCom.value, id)
    },
    async getRecommendList() {
      const { data: res } = await this.$http.get(this.api + '/getRecommendArticleList')

      if (res.status != 200) {
        this.$message.error(res.message)
        return
      }

      this.recommendList = res.data
    },
    getRecommendArticlePos(id) {
      for (let index = 0; index < this.recommendList.length; index++) {
        const element = this.recommendList[index]
        if (element.id == id) {
          return index + 1
        }
      }
      return false
    },
    async addRecommendArticle(pos, id) {
      // 添加
      const { data: res } = await this.$http.post(
        '/admin/addRecommendArticle',
        {
          id: id,
          pos: pos
        },
        formDataConfig
      )

      if (res.status != 200) {
        this.$message({
          type: 'info',
          message: res.message
        })
        return
      }

      this.$message({
        type: 'success',
        message: res.message
      })

      this.getRecommendList()
    },
    async deleteRecommendArticle(id) {
      // console.log('删除')
      console.log(id)
      const { data: res } = await this.$http.delete('/admin/deleteRecommendArticle', {
        params: {
          id: id
        }
      })

      if (res.status != 200) {
        this.$message({
          type: 'info',
          message: res.message
        })
        return
      }

      this.$message({
        type: 'success',
        message: res.message
      })

      this.getRecommendList()
    },
    handleCurrentPageNum(e) {
      this.pageNum = e
    },
    handleInput() {
      this.pageNum = 1

      if (!this.searchInput || this.searchInput.length === 0 || this.searchInput === '') {
        this.getData()
        return
      }

      // 模糊搜索内容
      this.searchArticleData(this.searchInput)
    },

    async searchArticleData(input) {
      const { data: res } = await this.$http.get(this.api + '/getArticleByTitleBlur', {
        params: { title: input, pageNum: this.pageNum, size: this.size, isPager: true }
      })
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
      }
      // console.log(res)
      this.tableData = res.data.list
      this.totalLength = res.data.total
    }
  },

  mounted() {
    this.getData()
    this.getRecommendList()
  }
}
</script>

<style lang="scss" scoped>
.article-cover {
  border-radius: 5px;
}

.title {
  font-size: 25px;
  font-weight: 900;
  text-align: center;
}

.el-tag {
  margin: 7px;
  display: inline-block;
  cursor: pointer;
}

.bdbottom {
  border-bottom: 1px solid #eee;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
