const formatSize = (size)=>{

  if (size<1024) return size.toFixed(2)+" Byte"

  size = size/1024
  if (size<1024) return size.toFixed(2)+" KB"

  size = size/1024
  if(size<1024) return size.toFixed(2)+" MB"

  size = size/1024
  if(size<1024) return size.toFixed(2)+" GB"

  

}

export default formatSize

