<template>
  <!-- 引入时需要注意这里的visible -->
  <div
    class="can-move-warp"
    v-if="visible"
    :style="position + ';width:' + width + 'px;' + 'height:' + height + 'px;'"
    :id="title"
  >
    <div class="title" @mousedown="handleMouseDown" @mouseup="handleMouseUp">
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'canMove',
  props: {
    title: String,
    position: String,
    width: {
      type: String,
      default: '250'
    },
    height: {
      type: String,
      default: '200'
    },
    visible: {
      type: Boolean
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleMouseDown(e) {
      if (e.preventDefault) {
        //标准模式
        e.preventDefault()
      } else {
        //ie8及以下支持
        e.returnValue = false
      }
      if (e.button != 0) return
      let startx = e.clientX
      let starty = e.clientY
      var _target = document.querySelector('#' + this.title)
      //在此处可调整长按后移动时会上下左右跳动的误差.
      let mistakeLeft = 10 //误差
      let mistakeTop = 2 //误差
      // 移动位置 = 浏览器x - 相对于其包含元素（父元素）的水平偏移量（左侧边缘与父元素左侧边缘之间的距离）。 - 可移动目标的左水平偏移量 + 误差
      let mouseRealx = startx - e.target.offsetLeft - _target.offsetLeft + mistakeLeft
      let mouseRealy = starty - e.target.offsetTop - _target.offsetTop - mistakeTop
      let ww = document.documentElement.clientWidth
      let wh = window.innerHeight
      document.onmousemove = function (ev) {
        var event = ev || window.event
        if (event.clientY < 0 || event.clientX < 0 || event.clientY > wh || event.clientX > ww) {
          return false
        }
        var endx = event.clientX - mouseRealx
        var endy = event.clientY - mouseRealy
        _target.style.left = endx + 'px'
        _target.style.top = endy + 'px'
      }
    },
    handleMouseUp() {
      document.onmousemove = null
    }
  }
}
</script>

<style lang="scss" scoped>
.can-move-warp {
  background-color: #fff;
  position: absolute;
  border-radius: 10px;
  padding: 10px;
  padding-top: 0;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  // flex-direction: column;
  color: #606266;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 18px;
}

.title {
  width: 100%;
  font-size: 20px;
  text-align: center;
  border-bottom: #606266 1px solid;
  padding-top: 10px;
  cursor: pointer;

  .close {
    font-size: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
  }
}
</style>
