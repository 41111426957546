<template>
  <el-dialog title="添加轮播图" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <div class="cover-wrapper" v-infinite-scroll="load" infinite-scroll-distance="10">
      <div
        :class="['cover-item', currentId === item.id ? 'isChoose' : '']"
        v-for="item in currentList"
        @click="handleSelect(item.id)"
        :key="item.id"
        v-show="!checkIdExist(item.id)"
      >
        <el-image :src="mediaIp + item.coverUrl"></el-image>
        <div class="title">{{ item.title }}</div>
        <div class="date">{{ formatDate(item.createdAt) }}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { api, mediaIp } from '@/global/global'
import formatDate from '@/utils/formatDate'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    visible(n) {
      this.dialogVisible = n
      if (n) {
        this.getSwiperList()
      }
    }
  },
  data() {
    return {
      pageNum: 1,
      size: 30,
      dialogVisible: false,
      currentList: [],
      swiperList: [],
      total: 0,
      currentId: '',
      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin',
      mediaIp: mediaIp,
      formatDate: formatDate
    }
  },
  methods: {
    async getArticleData() {
      const { data: res } = await this.$http.get(this.api + '/getAllArticle', {
        params: {
          pageNum: this.pageNum,
          size: this.size
        }
      })

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      if (this.pageNum === 1) {
        this.currentList = res.data.list
      } else {
        this.currentList.push(...res.data.list)
      }
      this.total = res.data.total
    },
    handleSelect(id) {
      this.currentId = id
    },
    load() {
      this.pageNum++
      this.getArticleData()
    },
    handleClose() {
      this.$emit('close')
    },
    handleSubmit() {
      this.$emit('submit', this.currentId)
    },
    async getSwiperList() {
      const { data: res } = await this.$http.get(this.api + '/getSwiperArticleList')

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      // console.log(res)

      this.swiperList = res.data
    },
    checkIdExist(id) {
      var flag = false
      this.swiperList.forEach((item) => {
        if (item.id == id) {
          flag = true
        }
      })
      return flag
    }
  },
  computed: {},
  mounted() {
    this.getArticleData()
  }
}
</script>

<style lang="scss" scoped>
.cover-wrapper {
  height: 500px;
  max-width: calc(220px * 5 + 20px);
  margin: 0 auto;
  // background-color: #409eff;
  overflow: auto;
}

.cover-item {
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;

  .el-image {
    width: 100%;
    height: 150px;
    border: 1px solid transparent;
  }
}

.cover-item:hover,
.isChoose {
  background-color: #409eff;
  color: #fff;
  .el-image {
    border: 1px solid #fff;
  }
}

.title {
  margin-bottom: 5px;
}
.title,
.date {
  text-align: center;
}
.date {
  font-size: 10px;
}
</style>
