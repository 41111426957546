<template>
  <div class="nav">
    <div class="top">
      <div class="show-menu-btn" @click="handleShow">
        <i :class="iscollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div>
      <div class="top-fun-wrapper">
        <div class="top-fun-item">
          <img :src="mediaIp + admin.headPath" alt="" />
          <div class="fun-content">
            <div class="fun-content-info">
              <i class="el-icon-s-custom"></i>
              {{ admin.nickname }}
            </div>
            <div class="fun-content-item" @click="handleLogout">退出登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mediaIp } from '@/global/global'
export default {
  name: 'NavComponent',
  props: {
    iscollapse: {
      type: Boolean
    }
  },
  data() {
    return {
      admin: {},
      mediaIp: mediaIp
    }
  },
  methods: {
    handleShow() {
      this.$emit('showCloseMenu')
    },
    handleLogout() {
      window.sessionStorage.removeItem('token')
      this.$router.push('/login')
    }
  },
  mounted() {
    this.admin = JSON.parse(window.sessionStorage.getItem('info'))
  }
}
</script>

<style lang="scss" scoped>
.nav {
  border-bottom: 1px solid #dcdfe6;
}

.top {
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .show-menu-btn,
  .top-fun-item {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .show-menu-btn {
    font-size: 30px;
    cursor: pointer;
  }

  .top-fun-wrapper {
    height: 100%;
    .top-fun-item:first-child {
      margin-right: 50px;
    }
    .top-fun-item {
      height: 100%;
      padding: 3px;
      width: 40px;
      // background-color: aqua;
      box-sizing: border-box;
      margin-left: 5px;
      position: relative;
      cursor: pointer;
      // background-color: #fff;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .fun-content {
        top: 50px;
        position: absolute;
        z-index: 999;
        width: 130px;
        display: none;
        // height: 200px;
        background-color: #fff;
        box-shadow: rgba($color: #000000, $alpha: 0.1) 0 0 6px 1px;
        padding: 10px 5px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 14px;
        .fun-content-info {
          margin: 5px 0;
          text-align: center;
        }

        .fun-content-item {
          padding: 5px 0;
          text-align: center;
          cursor: pointer;
          border-radius: 5px;
        }

        .fun-content-item:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }
    }
  }

  .top-fun-item:hover {
    .fun-content {
      display: block;
    }
  }
}
</style>
