const cuterDefualtOptions = {
  img: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
  outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
  outputType: 'jpeg', //裁剪生成图片的格式（jpeg || png || webp）
  info: true, //图片大小信息
  canScale: true, //图片是否允许滚轮缩放
  autoCrop: true, //是否默认生成截图框
  autoCropWidth: 200, //默认生成截图框宽度
  autoCropHeight: 200, //默认生成截图框高度
  fixed: true, //是否开启截图框宽高固定比例
  fixedNumber: [1, 1], //截图框的宽高比例
  full: false, //false按原比例裁切图片，不失真
  fixedBox: true, //固定截图框大小，不允许改变
  canMove: true, //上传图片是否可以移动
  canMoveBox: false, //截图框能否拖动
  original: true, //上传图片按照原始比例渲染
  centerBox: false, //截图框是否被限制在图片里面
  height: true, //是否按照设备的dpr 输出等比例图片
  infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
  maxImgSize: 3000, //限制图片最大宽度和高度
  enlarge: 1, //图片根据截图框输出比例倍数
  mode: 'auto', //图片默认渲染方式
  fillColor: '#ffffff'
}

/**
 * 发送form-data  的设置
 */
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

/**
 * 发送json-data 的设置
 */
const jsonDataConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}

/**
 * 图片服务器地址
 */

// const mediaIp = 'http://localhost:8088'
const mediaIp = 'https://server.superlwb0608.top'

/**
 * api 服务器地址
 */

const api = mediaIp

export { cuterDefualtOptions, formDataConfig, jsonDataConfig, mediaIp, api }
