<!-- 文章管理的图片管理专属 -->
<template>
  <div class="image-item">
    <el-image
      :src="mediaIp + image.path"
      :preview-src-list="[mediaIp + image.path]"
      ref="image"
      @load="handleOnload"
      class="image"
    ></el-image>
    <div class="copy-url-btn-wrapper" @click="handleCopyUrl(mediaIp + image.path)">
      <div class="in-wrapper">
        <i class="el-icon-document-copy"></i>
        复制url
      </div>
    </div>
    <div class="view-wrapper" @click="handleShowImage">
      <div class="in-wrapper">
        <i class="el-icon-view"></i>
        浏览图片
      </div>
    </div>
  </div>
</template>

<script>
import { mediaIp } from '@/global/global'
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      mediaIp: mediaIp
    }
  },
  methods: {
    handleShowImage() {
      // 显示图片
      this.$refs.image._data.showViewer = true
    },
    async handleCopyUrl(e) {
      // console.log(e)
      try {
        await navigator.clipboard.writeText(e)
        this.$message({
          message: '复制成功',
          type: 'success'
        })
      } catch (error) {
        this.$notify.error({
          title: '错误',
          dangerouslyUseHTMLString: true,
          message: `复制失败,需要手动粘贴<br>该通知10s自动消失<br>url:<textarea style="width: 250px; overflow: auto">${e}</textarea><div style="width: 250px; overflow: auto">${error}</div>`,
          duration: 10000
        })
      }
    },
    handleOnload(e) {
      var height = this.$refs.image.imageHeight
      var width = this.$refs.image.imageWidth
      this.$emit('onload')
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.image-item {
  width: 150px;
  // height: 100px;
  height: auto;
  // margin: 5px;
  position: absolute;
  line-height: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  height: auto;
  .image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .view-wrapper,
  .copy-url-btn-wrapper {
    position: absolute;
    display: none;
    width: 100%;
    height: 49%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    left: 0;
    font-size: 13px;
    overflow: hidden;
    color: #fff;
    cursor: pointer;

    i {
      margin-right: 5px;
    }

    .in-wrapper {
      width: 100%;
      height: 100%;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      box-sizing: border-box;
    }

    .in-wrapper:hover {
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  .copy-url-btn-wrapper {
    top: 0;
    border-radius: 5px 5px 0 0;
  }

  .view-wrapper {
    bottom: 0;
    border-radius: 0 0 5px 5px;
    // pointer-events: none;
  }
}

.image-item:hover {
  .view-wrapper,
  .copy-url-btn-wrapper {
    display: block;
  }
}
</style>
