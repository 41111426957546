import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import LoginView from '@/views/LoginView'
import AdminView from '@/views/AdminView'
import ImageView from '@/views/ImageView'
import AddOrEidtArticleView from '@/views/AddOrEidtArticleView'
import ArticleOrCommnetView from '@/views/ArticleOrCommnetView'
import SwiperView from '@/views/SwiperView'
import BlogCommentView from '@/views/BlogCommentView'
import BlogConfigView from '@/views/BlogConfigView'
import FunctionView from '@/views/FunctionView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login' // 路由重定向
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    redirect: '/welcome', // 路由重定向
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: WelcomeView
      },
      {
        path: '/adminInfo',
        name: 'adminInfo',
        component: AdminView
      },
      {
        path: '/image',
        name: 'image',
        component: ImageView
      },
      {
        path: '/addOrEidtArticle',
        name: 'addOrEidtArticle',
        component: AddOrEidtArticleView
      },
      {
        path: '/articleOrCommnet',
        name: 'articleOrCommnet',
        component: ArticleOrCommnetView
      },
      {
        path: '/swiper',
        name: 'swiper',
        component: SwiperView
      },
      {
        path: '/blogComment',
        name: 'blogComment',
        component: BlogCommentView
      },
      {
        path: '/blogConfig',
        name: 'blogConfig',
        component: BlogConfigView
      },
      {
        path: '/function',
        name: 'function',
        component: FunctionView
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 从哪个路径过来的
  // next 是一个函数， 表示放行
  // 1. next（） 放行  2. next('/login') 强制跳转到login

  // next()

  // 如果直接访问的是登录页面，放行
  if (to.path === '/login' || to.path === '/') {
    window.sessionStorage.removeItem('token')
    next()
    return
  }

  // 否则需要验证token
  const tokenStr = window.sessionStorage.getItem('token')

  console.log(tokenStr)
  // 如果tokenstr为空，返回
  if (!tokenStr) {
    return next('/login')
  }
  // 否则直接放行
  else {
    return next()
  }
})

export default router
