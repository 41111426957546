<template></template>

<script>
export default {
  data() {
    return {
      title: '',
      options: []
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
