<template>
  <div>
    <div class="wrapper">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>小功能管理</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input placeholder="请输入功能名称" v-model="searchFunc" clearable>
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-button slot="append" icon="el-icon-search" @click="handleSearch()"></el-button>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="dialogFormVisible = true">添加新小功能</el-button>
          </el-col>
        </el-row>

        <div class="table-wrapper">
          <el-table height="670" :data="tableData" class="table">
            <el-table-column prop="path" label="封面" width="250">
              <template slot-scope="scope">
                <el-image
                  :src="mediaIp + scope.row.cover.path"
                  :preview-src-list="[mediaIp + scope.row.cover.path]"
                  class="fun-cover"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="功能名称" width="450"></el-table-column>
            <el-table-column prop="icon" label="icon" width="100">
              <template slot-scope="scope">
                <el-image
                  :src="mediaIp + scope.row.icon.path"
                  :preview-src-list="[mediaIp + scope.row.icon.path]"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="articleId" label="文章id" width="100"></el-table-column>
            <el-table-column prop="articleName" label="文章标题" width="120"></el-table-column>

            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button type="text" style="color: #45d3a0" @click="handleShowInfo(scope.row.articleId)">
                  查看
                </el-button>
                <el-button type="text" @click="handleEditInfo(scope.row)">编辑</el-button>
                <el-button type="text" style="color: #f56c6c" @click="handleDeleteById(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pageter">
          <el-pagination
            background
            layout="total,prev, pager, next"
            :total="totalLength"
            :page-size="size"
            @current-change="handleCurrentPageNum"
          ></el-pagination>
        </div>

        <!-- 添加新小功能对话框 -->
        <el-dialog title="添加小功能" :visible.sync="dialogFormVisible" @close="handleClose">
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="名称" prop="name">
              <el-input v-model="form.name" style="width: 200px"></el-input>
            </el-form-item>

            <el-form-item label="封面" prop="cover">
              <div class="fun-cover-wrapper">
                <div
                  class="fun-no-cover"
                  v-if="form.cover.path === '' || form.cover.path.length === 0"
                  @click="
                    chooseVisible = true
                    choose = 'cover'
                  "
                >
                  +
                </div>
                <img
                  :src="this.mediaIp + form.cover.path"
                  alt=""
                  @click="
                    chooseVisible = true
                    choose = 'cover'
                  "
                  class="fun-cover"
                  v-else
                />
              </div>
            </el-form-item>
            <el-form-item label="icon" prop="icon">
              <div class="fun-icon-wrapper">
                <div
                  class="fun-no-cover"
                  v-if="form.icon.path === '' || form.icon.path.length === 0"
                  @click="
                    chooseVisible = true
                    choose = 'icon'
                  "
                >
                  +
                </div>
                <img
                  :src="this.mediaIp + form.icon.path"
                  alt=""
                  @click="
                    chooseVisible = true
                    choose = 'icon'
                  "
                  class="fun-icon"
                  v-else
                />
              </div>
            </el-form-item>
            <el-form-item label="绑定文章" prop="articleName">
              <el-select
                style="width: 100%"
                v-model="searchArticle"
                filterable
                default-first-option
                placeholder="请输入文章题目进行添加/搜索"
                remote
                :remote-method="remoteMethod"
                @change="handleChange"
              >
                <el-option
                  v-for="item in articles"
                  :key="item.id"
                  :label="item.title"
                  :value="{ id: item.id, title: item.title }"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="handlePostForm">确 定</el-button>
          </div>
        </el-dialog>

        <cut-image
          :visible.sync="chooseVisible"
          @close="chooseVisible = false"
          @submit="handleSubmitImg"
          :option="option"
        ></cut-image>

        <ArticleComponent :visible="showInfo" :article="currFuncArticle" @close="showInfo = false"></ArticleComponent>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleComponent from '@/components/ArticleComponent.vue'
import CutImage from '@/components/CutImageComponent.vue'
import { cuterDefualtOptions, jsonDataConfig, formDataConfig, mediaIp, api } from '@/global/global'
export default {
  components: {
    CutImage,
    ArticleComponent
  },
  data() {
    return {
      searchFunc: '',
      currId: '',
      option: cuterDefualtOptions,
      dialogFormVisible: false,
      chooseVisible: false,
      showInfo: false,
      currFuncArticle: {
        commentList: []
      },

      choose: 'cover', // cover表示上传封面，icon表示上传icon
      searchArticle: '',
      articles: [],
      // 切换模式，切换api
      mediaIp: mediaIp,
      form: {
        name: '',
        cover: {
          id: '',
          path: ''
        },
        icon: {
          id: '',
          path: ''
        },
        articleId: '',
        articleName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入功能名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        cover: [{ required: true, message: '请上传封面图片', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传icon', trigger: 'blur' }],
        articleName: [{ required: true, message: '请选择绑定的文章', trigger: 'blur' }]
      },
      pageNum: 1,
      size: 3,
      totalLength: 0,
      tableData: []
      // 切换模式，切换api
      // api: process.env.NODE_ENV === 'production' ? api + '/blog' : '/admin'
    }
  },
  watch: {
    searchFunc() {
      this.getData()
    },
    pageNum(n, o) {
      this.getData()
    }
  },
  methods: {
    async handleShowInfo(e) {
      this.showInfo = true

      // 通过id获取文章信息

      const { data: res } = await this.$http.get('/blog/getArticleById?id=' + e)

      this.currFuncArticle = res.data
    },
    handleEditInfo(e) {
      this.form = e
      this.searchArticle = e.articleName
      this.dialogFormVisible = true
    },
    async handlePostForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }
      })

      const { data: res } = await this.$http.post('/admin/saveFunc', this.form, jsonDataConfig)

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify({
        title: '成功',
        message: '保存成功',
        type: 'success'
      })

      this.dialogFormVisible = false
      this.getData()
    },
    async handleSubmitImg(e) {
      // 上传图片

      var url = ''
      if (this.choose === 'cover') {
        url = '/admin/uploadFuncCover'
      } else {
        url = '/admin/uploadFuncIcon'
      }

      const { data: res } = await this.$http.post(
        url,
        {
          base64: e.base64,
          adminName: JSON.parse(window.sessionStorage.getItem('info')).username
        },
        formDataConfig
      )
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify({
        title: '成功',
        message: '上传图片成功',
        type: 'success'
      })

      console.log(res.data)

      if (this.choose === 'cover') {
        this.form.cover = {
          id: res.data.id,
          path: res.data.path
        }
      } else {
        this.form.icon = {
          id: res.data.id,
          path: res.data.path
        }
      }

      this.chooseVisible = false
    },
    async remoteMethod(e) {
      const { data: res } = await this.$http.get('/blog/getArticleByTitleBlur', {
        params: { title: e, pageNum: 1, size: 10, isPager: false }
      })
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
      }
      this.articles = res.data.list
    },
    async handleDeleteById(id) {
      const confirmRes = await this.$confirm('此操作将该功能删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (confirmRes !== 'confirm') {
        return
      }
      const { data: res } = await this.$http.delete('/admin/logicDeleteArticleById', {
        params: {
          id: id
        }
      })

      if (res.status != 200) {
        this.$message.error('删除失败')
        console.log(res.message)
        return
      }

      this.$message({
        type: 'success',
        message: '删除成功!'
      })

      this.getData()
    },
    handleChange(e) {
      // this.articles = []
      this.searchArticle = e.title
      this.form.articleId = e.id
      this.form.articleName = e.title
    },
    handleClose() {
      this.form = {
        name: '',
        cover: {
          id: '',
          path: ''
        },
        icon: {
          id: '',
          path: ''
        },
        articleId: '',
        articleName: ''
      }
      this.searchArticle = ''
    },
    handleCurrentPageNum(e) {
      this.pageNum = e
    },
    handleSearch() {
      this.getData()
    },
    async getData() {
      const { data: res } = await this.$http.get('/blog/getFunctionsByTitleBlur', {
        params: {
          name: this.searchFunc,
          pageNum: this.pageNum,
          size: this.size,
          isPager: true
        }
      })

      // console.log(res.data)
      this.totalLength = res.data.total

      this.tableData = res.data.list
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 0;
  box-sizing: border-box;
}

.fun-cover-wrapper {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
}

.fun-cover {
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.fun-icon-wrapper {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;

  .fun-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.fun-no-cover {
  width: 100px;
  height: 100px;
  border: 1px dashed rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  cursor: pointer;
}
</style>
