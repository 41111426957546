<template>
  <el-dialog title="文章信息" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <div class="show-wrapper" ref="show">
      <div class="title">《{{ article.title }}》</div>
      <div class="info">
        <div class="info-item">创建时间：{{ formatDate(article.createdAt) }}</div>
        <div class="info-item">更新时间：{{ formatDate(article.updatedAt) }}</div>
        <div class="info-item">type：{{ article.typeName }}</div>
        <div class="info-item">tag：{{ article.tagName }}</div>
        <div class="info-item">评论数：{{ article.commentList.length }}</div>
        <div class="info-item">点赞数：{{ article.likeCount }}</div>
        <div class="info-item">浏览数：{{ article.watchCount }}</div>
      </div>
      <!-- preview-class 为主题的样式类名，例如vuepress就是vuepress-markdown-body -->
      <v-md-preview-html :html="html" preview-class="vuepress-markdown-body"></v-md-preview-html>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import formatDate from '@/utils/formatDate'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    article: {
      type: Object,
      required: true
    }
  },
  watch: {
    visible(n) {
      // 将当前元素的滚动条置顶
      if (!n) this.$refs.show.scrollTop = 0
      this.dialogVisible = n
    },
    article(n) {
      this.html = n.content
    }
  },
  data() {
    return {
      dialogVisible: false,
      html: '',
      formatDate: formatDate
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.show-wrapper {
  width: 100%;
  height: 500px;
  overflow-y: auto;

  .title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }

  .info {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .info-item {
      display: inline-block;
      width: calc(100% / 3);
      // background-color: aqua;
      margin-top: 10px;
    }
  }
}
</style>
