<template>
  <div>
    <div class="wrapper">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>图管理</el-breadcrumb-item>
        <el-breadcrumb-item>图片</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="table-wrapper">
        <el-table height="720" :data="currentData" class="table" :row-class-name="tableRowClassName">
          <el-table-column prop="path" label="图片" width="250">
            <template slot-scope="scope">
              <el-image
                :src="mediaIp + scope.row.path"
                :preview-src-list="[mediaIp + scope.row.path]"
                :class="[
                  'image',
                  scope.row.path === '/images/headImg/superlwb_headImg_1691495029677.jpg' ? 'isHeadImg' : ''
                ]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="图片名称" width="450"></el-table-column>
          <el-table-column prop="kind" label="图片种类" width="100"></el-table-column>
          <el-table-column prop="size" label="图片大小" width="120">
            <template slot-scope="scope">
              <span>{{ formatSize(scope.row.size) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="usedCount" label="引用状态" width="100">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.usedCount === 0">未被引用</el-tag>
              <el-tag type="success" v-else>{{ scope.row.usedCount }} 次</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button @click="handleShowInfo(scope.row)" type="text">查看</el-button>
              <el-button
                @click="handleSetHeadImg(scope.row)"
                type="text"
                style="color: #67c23a"
                v-if="scope.row.kind === 'headImg'"
              >
                设为头像
              </el-button>
              <el-button
                type="text"
                style="color: #f56c6c"
                @click="handleDeleteById(scope.row.id)"
                v-if="scope.row.usedCount === 0"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pageter">
        <div class="btn-wrapper">
          <el-tooltip class="item" effect="dark" content="检查所有图片被使用情况" placement="top">
            <el-button type="primary" class="check-btn" @click="handleCheckAllImage">检查所有图片</el-button>
          </el-tooltip>

          <el-button type="success" class="refesh-btn" @click="handleRefesh">刷新</el-button>
        </div>
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="totalLength"
          :page-size="size"
          @current-change="handleCurrentPageNum"
        ></el-pagination>
      </div>

      <ShowImageInfo :image="currImage" :visible.sync="showInfo" @cancel="showInfo = false"></ShowImageInfo>
    </div>
  </div>
</template>

<script>
import { mediaIp } from '@/global/global'
import formatSize from '@/utils/formatSize'
import ShowImageInfo from '@/components/ShowImageInfoComponent.vue'
export default {
  components: {
    ShowImageInfo
  },
  data() {
    return {
      currentData: [],
      pageNum: 1,
      size: 5,
      totalLength: 0,
      // 媒体服务器ip地址
      mediaIp: mediaIp,
      formatSize: formatSize,
      // 显示图片详细信息
      showInfo: false,
      currImage: null
    }
  },
  watch: {
    pageNum(n, o) {
      this.getData()
    }
  },
  methods: {
    // 根据状态改变颜色
    tableRowClassName({ row, rowIndex }) {
      // if (row.usedCount === 0) {
      //   return 'warning-row'
      // }
      // return ''
    },
    // 获取数据
    async getData(successCallBackFuction) {
      const { data: res } = await this.$http.get('/admin/allImage', {
        params: {
          pageNum: this.pageNum,
          size: this.size
        }
      })

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }
      this.currentData = res.data.result
      this.totalLength = res.data.total
      // 如果 successCallBackFuction 不为空，执行后面的函数
      successCallBackFuction && successCallBackFuction()
    },
    handleCurrentPageNum(e) {
      this.pageNum = e
    },
    handleShowInfo(e) {
      this.currImage = e
      this.showInfo = true
    },
    async handleCheckAllImage() {
      // 提示等待，因为数据量大时会比较久
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { data: res } = await this.$http.get('/admin/checkImageIsUsed')
      loading.close()
      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      this.$notify.success({
        title: '成功',
        message: '已成功检查所有图片'
      })

      this.getData()
    },
    handleDeleteById(e) {
      const id = e
      this.$confirm('是否永久删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.delete(`/admin/deleteImageById`, { params: { id: id } })

          if (res.status != 200) {
            this.$notify.error({
              title: '错误',
              message: 'status：' + res.status + ' error：' + res.message
            })
            return
          }

          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })

          // 从currentdata中删除该元素
          this.deleteImageFromArrById(id)

          // 总长度减一
          this.totalLength--
        })
        .catch((err) => {
          // console.log(err)
        })
    },
    deleteImageFromArrById(id) {
      for (let i = 0; i < this.currentData.length; i++) {
        if (this.currentData[i].id === id) {
          this.currentData.splice(i, 1)
          return
        }
      }
    },
    handleRefesh() {
      this.getData(() => {
        this.$notify.success({
          title: '成功',
          message: '刷新成功'
        })
      })
    },
    // 这里有adminname
    handleSetHeadImg(e) {
      const path = e.path
      const adminName = 'superlwb'

      this.$confirm(`是否设置为 ${adminName} 的头像?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.patch('/admin/updateHeadImg', {
            username: adminName,
            path: path
          })

          if (res.status != 200) {
            this.$notify.error({
              title: '错误',
              message: 'status：' + res.status + ' error：' + res.message
            })
            return
          }

          this.$notify.success({
            title: '成功',
            message: '设置成功'
          })

          this.handleCheckAllImage()
        })
        .catch((err) => {})
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.image {
  height: 100px;
}

.isHeadImg {
  position: relative;
}

.isHeadImg::before {
  position: absolute;
  content: '当前用户头像';
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  top: 0;
  left: 0;
  color: #fff;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  // 事件穿透
  pointer-events: none;
}

.el-table .cell {
  text-align: center;
}
</style>
