const formatDate=(dateStr)=>{

  if (!dateStr) return

  const date=new Date(dateStr)
  let year = date.getFullYear()
  let month = date.getMonth() +1
  month= month<10?"0"+month:month
  let day = date.getDate()
  day= day<10?"0"+day:day
  
  // 时间
  let hour = date.getHours()
  hour= hour<10?"0"+hour:hour

  let min  = date.getMinutes()
  min= min<10?"0"+min:min

  let sec = date.getSeconds()
  sec= sec<10?"0"+sec:sec

  const res = year+"-"+month+"-"+day+" "+hour+":"+min+":"+sec



  return  res

}

export default formatDate