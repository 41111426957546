import { render, staticRenderFns } from "./ShowImageInfoComponent.vue?vue&type=template&id=64187bf4&"
import script from "./ShowImageInfoComponent.vue?vue&type=script&lang=js&"
export * from "./ShowImageInfoComponent.vue?vue&type=script&lang=js&"
import style0 from "./ShowImageInfoComponent.vue?vue&type=style&index=0&id=64187bf4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports