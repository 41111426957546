<template>
  <div>
    <div class="wrapper">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>博客管理</el-breadcrumb-item>
        <el-breadcrumb-item>评论管理</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="table-wrapper">
        <el-table :data="commentList" class="table" height="720">
          <el-table-column type="expand">
            <template slot-scope="scope" v-if="scope.row.commentList.length != 0">
              <div class="title">回复管理({{ scope.row.commentList.length }})</div>
              <el-tag
                v-for="item in scope.row.commentList"
                :key="item.id"
                closable
                @click="handleShowCommentInfo(item)"
                @close="handleDeleteComment(scope.row, item)"
              >
                {{ item.content }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="评论内容" prop="content"></el-table-column>
          <el-table-column label="IP" prop="ip" width="100"></el-table-column>
          <el-table-column label="创建时间" width="100">
            <template slot-scope="scope">
              {{ formatDate(scope.row.createdAt) }}
            </template>
          </el-table-column>
          <el-table-column label="回复数" width="70">
            <template slot-scope="scope">
              {{ scope.row.commentList.length }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button @click="handleShowCommentInfo(scope.row)" type="text">查看</el-button>
              <el-button type="text" style="color: #f56c6c" @click="handleDeleteMessage(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pageter">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="totalLength"
          :page-size="size"
          :current-page="pageNum"
          @current-change="handleCurrentPageNum"
        ></el-pagination>
      </div>
    </div>
    <CommentComponent :visible="showComment" :comment="currComment" @close="showComment = false"></CommentComponent>
  </div>
</template>

<script>
import formatDate from '@/utils/formatDate'
import CommentComponent from '@/components/CommentComponent.vue'
export default {
  components: {
    CommentComponent
  },
  data() {
    return {
      commentList: [],
      showComment: false,
      currComment: {},
      formatDate: formatDate
    }
  },
  methods: {
    async getBlogCommentData() {
      const { data: res } = await this.$http.get('/blog/getBlogMsg')

      console.log(res)

      if (res.status != 200) {
        this.$message.error(res.message)
        return
      }

      this.commentList = res.data
    },
    handleShowCommentInfo(item) {
      this.currComment = item
      this.showComment = true
    },
    async handleDeleteMessage(id) {
      const confirmRes = await this.$confirm('此操作将该评论删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (confirmRes !== 'confirm') {
        return
      }

      const { data: res } = await this.$http.delete('/admin/deleteMessageById', {
        params: {
          id: id,
          articleId: ''
        }
      })

      if (res.status != 200) {
        this.$message.error('删除失败')
        console.log(res.message)
        return
      }

      this.$message({
        type: 'success',
        message: '删除成功!'
      })

      this.getBlogCommentData()
    },
    async handleDeleteComment(row, com) {
      const confirmRes = await this.$confirm('此操作将该评论删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => {})

      if (confirmRes !== 'confirm') {
        return
      }

      const { data: res } = await this.$http.delete('/admin/deleteCommentById', {
        params: {
          msgId: com.messageId,
          id: com.id
        }
      })

      if (res.status != 200) {
        this.$message.error('删除失败')
        console.log(res.message)
        return
      }

      this.$message({
        type: 'success',
        message: '删除成功!'
      })

      row.commentList = res.data.commentList
      // console.log(row)
    }
  },

  mounted() {
    this.getBlogCommentData()
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 25px;
  font-weight: 900;
  text-align: center;
}

.el-tag {
  margin: 10px;
  cursor: pointer;
}
</style>
