<template>
  <div class="home-content">
    <!-- 侧边菜单栏 -->
    <div class="app-left">
      <my-menu :iscollapse="isCollapse"></my-menu>
    </div>

    <!-- 右边主要内容区域 -->
    <div class="app-right">
      <!-- 顶部导航栏 -->
      <div class="app-top-nav">
        <my-nav @showCloseMenu="handleShow" :iscollapse="isCollapse"></my-nav>
      </div>
      <!-- 内容区域 -->
      <router-view class="app-view"></router-view>
    </div>
  </div>
</template>

<script>
import MyMenu from '@/components/MenuComponent.vue'
import MyNav from '@/components/NavComponent.vue'

export default {
  components: {
    MyMenu,
    MyNav
  },
  data() {
    return {
      isCollapse: true
    }
  },
  methods: {
    handleShow() {
      this.isCollapse = !this.isCollapse
    }
  },
  mounted() {}
}
</script>

<style lang="scss">
.home-content {
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;

  overflow: hidden;
}
</style>
